.chart-page {
  header {
    &.MuiAppBar-root {
      display: none;
    }
  }
  main {
    margin-top: 0 !important;
  }
  footer {
    display: none;
  }
}
